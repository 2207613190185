import { gql } from '@apollo/client';
import { UserIdentification } from '../user/fragments';
import { MasterEntityBasicField } from '../customers/fragment';
import MoneyFields from '../fragments/money/money-fields';
import { FileFields } from '../fileField';

export const InvoiceIdFields = gql`
  fragment InvoiceIdFields on InvoiceType {
    id
    globalAppId
    folio
    dateIssued
    receptionDate
    dateExpiration
    dateOperation
    hasExecutiveMerit @client
    daysToExecutiveMerit @client
    dateToPay
    paymentDate
    amountWithIva {
      ...MoneyFields
    }
  }
  ${MoneyFields}
`;

export const DownloadInvoiceDocumentFields = gql`
  fragment DownloadInvoiceDocumentFields on InvoiceType {
    id
    company {
      hasSiiCredentials
      hasInvoiceProviderCredentials
    }
    receiver {
      company {
        hasInvoiceProviderCredentials
      }
    }
  }
`;

export const InvoiceStatusFields = gql`
  fragment InvoiceStatusFields on InvoiceType {
    siiStatus
    creditNoteStatus
    loanedStatus
    loanedToRut
    loanedTo
    status
  }
`;

export const InvoiceAlertsFields = gql`
  fragment InvoiceAlertsFields on InvoiceType {
    isFunding @client
    lightningPaymentAuthorized @client
    isRatificated
    isForOrdering
    hasPaymentsEmitted
  }
`;

export const InvoiceDteTypeFields = gql`
  fragment InvoiceDteTypeFields on InvoiceDteTypeType {
    id
    code
    name
  }
`;

export const InvoiceDocumentsFields = gql`
  fragment InvoiceDocumentsFields on InvoiceDocumentManagerType {
    id
    globalAppId
    file {
      ...FileFields
    }
    type {
      id
      name
      shortName
      code
    }
  }
  ${FileFields}
`;

export const AssignmentFields = gql`
  fragment AssignmentFields on AssignmentType {
    id
    globalAppId
    certificate {
      ...FileFields
    }
    assignedDate
    fromMasterEntity {
      id
      name
      rut
    }
    toMasterEntity {
      id
      name
      rut
    }
    createdAt
    updatedAt
  }
  ${FileFields}
`;

export const PreofferFields = gql`
  fragment PreofferFields on PreOfferType {
    id
    monthlyRate
    defaultRate
    retentionRate
    preofferevaluationrequest {
      id
      status
      rejectionReasonLabel
      rejectionReason
      requestingPlatform {
        id
        code
      }
    }
  }
`;

export const PreofferBasicFields = gql`
  fragment PreofferBasicFields on PreOfferType {
    id
    monthlyRate
    defaultRate
    retentionRate
  }
`;

export const OfferFields = gql`
  fragment OfferFields on OfferType {
    id
    monthlyRate
    defaultRate
    retentionRate
    fundingDaysAfterExpiration
  }
`;

export const EvaluationRequestFields = gql`
  fragment EvaluationRequestFields on PreOfferEvaluationRequestType {
    id
    status
  }
`;

export const CollectionManagerFields = gql`
  fragment CollectionManagerFields on CollectionManagerType {
    id
    collectionPriority {
      id
      value
    }
    actions {
      id
      createdAt
      comment
      actionType
      author {
        ...UserIdentification
      }
    }
  }
  ${UserIdentification}
`;

export const InvoiceReferenceFields = gql`
  fragment InvoiceReferenceFields on InvoiceType {
    references {
      ...InvoiceIdFields
      dteType {
        ...InvoiceDteTypeFields
      }
    }
    reverseReferences {
      ...InvoiceIdFields
      dteType {
        ...InvoiceDteTypeFields
      }
    }
    creditNotes @client {
      ...InvoiceIdFields
    }
    debitNotes @client {
      ...InvoiceIdFields
    }
  }
  ${InvoiceIdFields}
  ${InvoiceDteTypeFields}
`;
export const InvoiceIntermediateReferenceFields = gql`
  fragment InvoiceIntermediateReferenceFields on InvoiceType {
    intermediateReferencesFrom {
      toInvoice {
        ...InvoiceIdFields
        dteType {
          ...InvoiceDteTypeFields
        }
      }
      amount {
        ...MoneyFields
      }
    }
    intermediateReferencesTo {
      fromInvoice {
        ...InvoiceIdFields
        dteType {
          ...InvoiceDteTypeFields
        }
      }
      amount {
        ...MoneyFields
      }
    }
    creditNotes @client {
      ...InvoiceIdFields
    }
    debitNotes @client {
      ...InvoiceIdFields
    }
  }
  ${MoneyFields}
  ${InvoiceIdFields}
  ${InvoiceDteTypeFields}
`;

export const CreditNoteFields = gql`
  fragment CreditNoteFields on InvoiceType {
    references {
      ...InvoiceIdFields
      dteType {
        ...InvoiceDteTypeFields
      }
    }
    creditNotes @client {
      ...InvoiceIdFields
    }
  }
  ${MoneyFields}
  ${InvoiceIdFields}
  ${InvoiceDteTypeFields}
`;

export const DocumentFinanceStateFields = gql`
  fragment DocumentFinanceStateFields on DocumentFinanceStateType {
    id
    estimatedPaymentDate
    paymentDate
    comments
    isAutomatic
    eventTime: date
    status {
      id
      code
      status
    }
    rejectionCode {
      id
      code
      reason
    }
    bank {
      id
      name
    }
  }
`;

export const InvoiceAttachableFields = gql`
  fragment InvoiceAttachableFields on InvoiceType {
    assignmentSet {
      ...AssignmentFields
    }
    documents {
      ...InvoiceDocumentsFields
    }
  }
  ${InvoiceDocumentsFields}
  ${AssignmentFields}
`;

export const InvoiceLoanedFields = gql`
  fragment InvoiceLoanedFields on InvoiceType {
    loanedTo
    loanedToRut
  }
`;

export const CessionsFields = gql`
  fragment CessionsFields on CessionType {
    id
    cessionDate
    transferor {
      ...MasterEntityBasicField
    }
    assignee {
      ...MasterEntityBasicField
    }
  }
  ${MasterEntityBasicField}
`;

export const InvoiceDetailFields = gql`
  fragment InvoiceDetailFields on InvoiceDetailType {
    id
    purchaseOrderFolio
    paymentMethod
    paymentTerm
  }
`;

export const TraceFields = gql`
  fragment TraceFields on TraceType {
    id
    eventCode
    eventTime
  }
`;

export const InvoiceHistoryStatusFields = gql`
  fragment InvoiceHistoryStatusFields on HistoryStatusType {
    id
    createdAt
    status
    statusBefore {
      id
      createdAt
      status
    }
  }
`;
